import {
  ConversationContainer, // 会话列表组件
  ChatContainer,
  AddContainer,
  ContactListContainer,
  SearchContainer,
  ContactInfoContainer, // 聊天（会话消息）组件
  useStateContext
} from './components'
import { FC, useEffect, useRef, useState } from 'react'
import { observer } from "mobx-react";
import "@xkit-yx/im-kit-ui/es/style/css";
import { SystemMessage } from '@xkit-yx/im-store';

export const IM: FC = observer(() => {
  const [type, setType] = useState<'chat' | 'contacts'>('chat')

  const { store, nim } = useStateContext()
  const [unReadSysMsgCount, setUnReadSysMsgCount] = useState(0);
  let [friendsInformationCount, setFriendsInformationCount] = useState(0);
  const sysMsgs = useRef<Map<string, SystemMessage>>(new Map())

  useEffect(() => {
    store.uiStore.selectedContactType = 'friendList'
    setUnReadSysMsgCount(Number(localStorage.getItem('unReadSysMsgCount')) || 0)
    const _sysMsgs = localStorage.getItem('sysMsgs')
    if (_sysMsgs) {
      sysMsgs.current = new Map(JSON.parse(_sysMsgs));
    }
    if (sysMsgs.current.size) {
      sysMsgs.current.forEach(val => {
        store.sysMsgStore.addApplyMsg(val)
      })
    }

    nim.on('updateSystemMessages', (result) => {
      sysMsgs.current.forEach((val, key) => {
        if (val.from === result[0].from && val.type == result[0].type) {
          sysMsgs.current.delete(key)
        }
      })
      localStorage.setItem('sysMsgs', JSON.stringify(Array.from(sysMsgs.current.entries())));
    })
  }, [])

  useEffect(()=>{
    const data = store.uiStore.sessionList;
    let totalUnreadCount = 0;
    data.map((it)=>{
      totalUnreadCount+=it.unread
    })
    setFriendsInformationCount(totalUnreadCount);
  },[store.uiStore.sessionList])

  useEffect(() => {
    window.parent.postMessage(JSON.stringify({
      unRead: store.uiStore.sessionUnread + store.sysMsgStore.unreadSysMsgCount,
      type: 'chatUnRead'
    }), '*')
    store.sysMsgStore.unReadSysMsgs.forEach((value, key) => {
      sysMsgs.current?.set(key, value);
    })

    localStorage.setItem('unReadSysMsgCount', store.sysMsgStore.unreadSysMsgCount + '')
    localStorage.setItem('sysMsgs', JSON.stringify(Array.from(sysMsgs.current.entries())));
    setUnReadSysMsgCount(store.sysMsgStore.unreadSysMsgCount)
  }, [store.uiStore.sessionUnread, store.sysMsgStore.unreadSysMsgCount])


  const goChat = () => {
    setType('chat')
  }

  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

    <div style={{ flex: 1, display: 'flex', borderTop: '1px solid #ddd', height: 0 }}>
      <div className='left' style={{ width: '300px', borderRight: '1px solid #ddd', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', width: '100%', margin: '0 auto', alignItems: 'center', padding: '20px' }}>
            <div style={{ marginRight: '8px', width: '100%' }}>
              <SearchContainer onClickChat={goChat} />
            </div>
            <AddContainer onClickChat={goChat} />
          </div>
        </div>
        <div style={{ flex: 1, borderTop: '1px solid #ddd', display: 'flex' }}>
          <div className='nav' style={{ borderRight: '1px solid #ddd', width: '70px', height: '100%' }}>
            <div onClick={() => setType('chat')} style={{
              textAlign: 'center', borderBottom: '1px solid #ddd', padding: '10px', cursor: 'pointer', position: 'relative'
            }}>
              对话
              {
                (friendsInformationCount && type !== 'chat') ? <sup data-show="true" className="ant-scroll-number ant-badge-count ant-badge-status-red"
                  title={friendsInformationCount + ''} style={{ top: '12px', right: '14px' }}>
                  <span className="ant-scroll-number-only" style={{ transition: 'none 0s ease 0s' }}>
                    <span className="ant-scroll-number-only-unit current">{friendsInformationCount > 100 ? '99+' : friendsInformationCount}</span>
                  </span>
                </sup>
                  : ''
              }
            </div>
            <div onClick={() => setType('contacts')} style={{
              textAlign: 'center', borderBottom: '1px solid #ddd', padding: '10px',
              cursor: 'pointer', position: 'relative'
            }}>
              通讯录
              {
                (unReadSysMsgCount && type == 'chat') ? <sup data-show="true" className="ant-scroll-number ant-badge-count ant-badge-status-red"
                  title={unReadSysMsgCount + ''} style={{ top: '12px', right: '14px' }}>
                  <span className="ant-scroll-number-only" style={{ transition: 'none 0s ease 0s' }}>
                    <span className="ant-scroll-number-only-unit current">{unReadSysMsgCount > 100 ? '99+' : unReadSysMsgCount}</span>
                  </span>
                </sup>
                  : ''
              }
            </div>
          </div>
          <div style={{ flex: 1, width: 0, height: '100%' }}>
            {
              type == 'chat' ? <ConversationContainer />
                : <ContactListContainer />
            }
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {
          type == 'chat' ? <ChatContainer />
            : <ContactInfoContainer
              onFriendItemClick={async (account) => {
                await store.sessionStore.insertSessionActive('p2p', account, true)
                setType('chat')
              }}
              onGroupItemClick={() => { setType('chat') }}
              afterSendMsgClick={() => setType('chat')}
            />
        }
      </div>
    </div>
  </div>
})