const LocaleConfig = {
  // common
  saveText: '保存',
  setText: '设置',
  saveSuccessText: '保存成功',
  saveFailedText: '保存失败',
  addFriendSuccessText: '添加好友成功',
  applyFriendSuccessText: '申请添加好友成功',
  addFriendFailedText: '添加好友失败',
  applyFriendFailedText: '申请添加好友失败',
  okText: '确定',
  cancelText: '取消',
  deleteText: '删除',
  recallText: '撤回',
  forwardText: '转发',
  forwardFailedText: '转发失败',
  sendBtnText: '发送',
  replyText: '回复',
  commentText: '留言',
  recentSessionText: '最近访问',
  you: '你',
  deleteFriendText: '删除好友',
  confirmDeleteText: '确定删除？',
  confirmDeleteFriendText: '确定删除好友？',
  deleteFriendSuccessText: '删除好友成功',
  deleteFriendFailedText: '删除好友失败',
  blackText: '拉黑好友',
  removeBlackText: '解除拉黑',
  blackSuccessText: '拉黑成功',
  blackFailedText: '拉黑失败',
  removeBlackSuccessText: '解除拉黑成功',
  removeBlackFailedText: '解除拉黑失败',
  maxSelectedText: '最多只能选择',
  selectedText: '已选',
  friendsText: '位好友',
  strangerText: '位陌生人',
  emailErrorText: '邮箱格式不正确',
  uploadLimitText: '图片视频或文件大小最大支持',
  uploadLimitUnit: 'M',
  uploadImgFailedText: '上传图片失败',
  accountText: '账号',
  nickText: '昵称',
  genderText: '性别',
  phoneText: '手机',
  emailText: '邮箱',
  signText: '签名',
  accountPlaceholder: '请输入账号',
  teamIdPlaceholder: '请输入群组 ID',
  nickPlaceholder: '请输入昵称',
  genderPlaceholder: '请选择性别',
  phonePlaceholder: '请输入手机号',
  emailPlaceholder: '请输入邮箱',
  signPlaceholder: '请输入签名',
  searchInputPlaceholder: '搜索好友或群组',
  searchTeamMemberPlaceholder: '搜索群成员',
  searchText: '搜索',
  man: '男',
  woman: '女',
  unknow: '未知',
  welcomeText: '欢迎使用文思逸言',
  notSupportMessageText: '暂不支持该消息',
  applyTeamText: '申请入群',
  applyTeamSuccessText: '申请入群成功',
  rejectText: '拒绝',
  acceptText: '同意',
  inviteTeamText: '邀请您加入群组',
  applyFriendText: '添加您为好友',
  acceptResultText: '已同意',
  rejectResultText: '已拒绝',
  beRejectResultText: '拒绝了好友申请',
  passResultText: '通过了好友申请',
  rejectTeamInviteText: '拒绝了群邀请',
  updateTeamAvatar: '更新了群头像',
  updateTeamName: '更新群名称为',
  updateTeamIntro: '更新了群介绍',
  updateTeamInviteMode: '更新了群权限“邀请他人权限”为',
  updateTeamUpdateTeamMode: '更新了群权限“群资料修改权限”为',
  updateAllowAt: '更新了“@所有人权限”为',
  updateTeamMute: '更新了“群禁言”为',
  onlyTeamOwner: '仅群主',
  teamAll: '所有人',
  closeText: '关闭',
  openText: '开启',
  inviteText: '邀请',
  aliasText: '备注',
  updateAliasSuccessText: '修改备注成功',
  updateAliasFailedText: '修改备注失败',
  sendText: '发送消息',
  noPermission: '您暂无权限操作',
  unreadText: '未读',
  readText: '已读',
  allReadText: '全部已读',
  amap: '高德地图',
  txmap: '腾讯地图',
  bdmap: '百度地图',
  callDurationText: '通话时长',
  callCancelText: '已取消',
  callRejectedText: '已拒绝',
  callTimeoutText: '已超时',
  callBusyText: '对方忙',
  // conversation-kit
  onDismissTeamText: '群已被解散',
  onRemoveTeamText: '您已被移出群组',
  textMsgText: '文本消息',
  customMsgText: '自定义消息',
  audioMsgText: '音频消息',
  videoMsgText: '视频消息',
  fileMsgText: '文件消息',
  callMsgText: '话单消息',
  geoMsgText: '地理位置消息',
  imgMsgText: '图片消息',
  notiMsgText: '通知消息',
  robotMsgText: '机器消息',
  tipMsgText: '提示消息',
  unknowMsgText: '未知消息',
  deleteSessionText: '删除会话',
  muteSessionText: '开启免打扰',
  unmuteSessionText: '取消免打扰',
  deleteStickTopText: '取消置顶',
  addStickTopText: '置顶消息',
  beMentioned: '[有人@我]',

  // contact-kit
  teamListTitle: '我的群组',
  friendListTitle: '我的好友',
  blackListTitle: '黑名单',
  msgListTitle: '消息中心',
  blackListDesc: '（你不会收到列表中任何联系人的消息）',
  teamMenuText: '我的群组',
  friendMenuText: '我的好友',
  blackMenuText: '黑名单',
  msgMenuText: '消息中心',
  acceptedText: '已同意该申请',
  acceptFailedText: '同意该申请失败',
  rejectedText: '已拒绝该申请',
  rejectFailedText: '拒绝该申请失败',
  getApplyMsgFailedText: '获取消息失败',

  // search-kit
  addFriendText: '添加好友',
  createTeamText: '创建群组',
  joinTeamText: '加入群组',
  joinTeamSuccessText: '加入群组成功',
  beRemoveTeamText: '被移出群组',
  addButtonText: '添加',
  addSuccessText: '添加成功',
  addFailedText: '添加失败',
  createButtonText: '创建',
  createTeamSuccessText: '创建群组成功',
  createTeamFailedText: '创建群组失败',
  chatButtonText: '去聊天',
  getRelationFailedText: '获取关系失败',
  accountNotMatchText: '查无此账号',
  teamIdNotMatchText: '查无此群号',
  searchButtonText: '查找',
  searchTeamPlaceholder: '请输入群名称',
  teamTitle: '群名称',
  teamAvatarText: '群头像',
  addTeamMemberText: '添加成员',
  searchEmptyText: '您暂未添加好友和群组',
  searchNoResText: '暂无搜索结果',
  searchFriendTitle: '好友',
  searchTeamTitle: '群组',
  notSupportJoinText: '讨论组无法直接加入，请联系管理员拉您入群',

  // chat-kit
  sendToText: '发送给',
  sendUsageText: '（按enter直接发送，shift+enter换行）',
  sendEmptyText: '不能发送空白消息',
  teamMutePlaceholder: '当前群聊禁言中',
  enterTeamText: '进入了群组',
  leaveTeamText: '离开了群组',
  teamMuteText: '群禁言',
  muteAllTeamSuccessText: '开启全员禁言成功',
  unmuteAllTeamSuccessText: '结束全员禁言成功',
  muteAllTeamFailedText: '开启全员禁言失败',
  unmuteAllTeamFailedText: '结束全员禁言失败',
  updateTeamSuccessText: '修改成功',
  updateTeamFailedText: '修改失败',
  leaveTeamSuccessText: '已成功退出此群',
  leaveTeamFailedText: '退出此群失败',
  dismissTeamSuccessText: '群解散成功',
  dismissTeamFailedText: '群解散失败',
  addTeamMemberSuccessText: '添加成员成功',
  addTeamMemberFailedText: '添加成员失败',
  addTeamMemberConfirmText: '请选择要添加的群成员',
  removeTeamMemberText: '移除成员',
  removeTeamMemberConfirmText: '是否确认移除该成员？',
  removeTeamMemberSuccessText: '移除成员成功',
  removeTeamMemberFailedText: '移除成员失败',
  teamTitleConfirmText: '群名称不能为空',
  teamAvatarConfirmText: '群头像不能为空',
  teamIdText: '群ID',
  teamSignText: '群介绍',
  teamTitlePlaceholder: '请输入群名称',
  teamSignPlaceholder: '请输入内容',
  teamOwnerText: '群主',
  teamManagerText: '群管理员',
  teamManagerEditText: '人员管理',
  teamManagerEmptyText: '暂无群管理人员',
  teamOwnerAndManagerText: '群主和管理员',
  updateTeamManagerSuccessText: '修改群管理员成功',
  updateTeamManagerFailText: '修改群管理员失败',
  userNotInTeam: '成员已不在群中',
  teamManagerLimitText: '谁可以修改资料',
  teamInviteModeText: '谁可以邀请新成员',
  teamAtModeText: '谁可以@所有人',
  teamMemberText: '群成员',
  teamInfoText: '群资料',
  teamPowerText: '群管理',
  dismissTeamText: '解散群组',
  transferOwnerText: '转让群主',
  newGroupOwnerText: '成为新群主',
  beAddTeamManagersText: '被任命为管理员',
  beRemoveTeamManagersText: '被移除管理员',
  transferTeamFailedText: '转让群主失败',
  transferToText: '转让给',
  transferTeamSuccessText: '转让群主成功',
  transferOwnerConfirmText: '是否确认转让群主',
  dismissTeamConfirmText: '是否确认解散该群组',
  leaveTeamTitle: '离开群组',
  leaveTeamConfirmText: '是否确认退出该群组',
  personUnit: '人',
  leaveTeamButtonText: '删除并退出',
  sendMsgFailedText: '消息发送失败',
  getHistoryMsgFailedText: '获取历史消息失败',
  sendBlackFailedText: '您已被对方拉入黑名单',
  recallMessageText: '撤回了一条消息',
  recallReplyMessageText: '该消息已撤回或删除',
  reeditText: '重新编辑',
  addChatMemberText: '添加聊天成员',
  chatHistoryText: '聊天记录',
  noMoreText: '没有更多消息了',
  receiveText: '您收到了新消息',
  strangerNotiText: '当前不是您的好友，请注意保护个人隐私安全。',
  nickInTeamText: '我在群里的昵称',
  editNickInTeamText: '编辑我在群里的昵称',
  updateMyMemberNickSuccess: '更新我的群昵称成功',
  updateMyMemberNickFailed: '更新我的群昵称失败',
  updateBitConfigMaskSuccess: '更新群消息免打扰成功',
  updateBitConfigMaskFailed: '更新群消息免打扰失败',
  imgText: '图片',
  videoText: '视频',
  onlineText: '[在线]',
  offlineText: '(离线)',

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: '[大笑]',
  Happy: '[开心]',
  Sexy: '[色]',
  Cool: '[酷]',
  Mischievous: '[奸笑]',
  Kiss: '[亲]',
  Spit: '[伸舌头]',
  Squint: '[眯眼]',
  Cute: '[可爱]',
  Grimace: '[鬼脸]',
  Snicker: '[偷笑]',
  Joy: '[喜悦]',
  Ecstasy: '[狂喜]',
  Surprise: '[惊讶]',
  Tears: '[流泪]',
  Sweat: '[流汗]',
  Angle: '[天使]',
  Funny: '[笑哭]',
  Awkward: '[尴尬]',
  Thrill: '[惊恐]',
  Cry: '[大哭]',
  Fretting: '[烦躁]',
  Terrorist: '[恐怖]',
  Halo: '[两眼冒星]',
  Shame: '[害羞]',
  Sleep: '[睡着]',
  Tired: '[冒星]',
  Mask: '[口罩]',
  ok: '[OK]',
  AllRight: '[好吧]',
  Despise: '[鄙视]',
  Uncomfortable: '[难受]',
  Disdain: '[不屑]',
  ill: '[不舒服]',
  Mad: '[愤怒]',
  Ghost: '[鬼怪]',
  Angry: '[发怒]',
  Unhappy: '[不高兴]',
  Frown: '[皱眉]',
  Broken: '[心碎]',
  Beckoning: '[心动]',
  Ok: '[好的]',
  Low: '[低级]',
  Nice: '[赞]',
  Applause: '[鼓掌]',
  GoodJob: '[给力]',
  Hit: '[打你]',
  Please: '[阿弥陀佛]',
  Bye: '[拜拜]',
  First: '[第一]',
  Fist: '[拳头]',
  GiveMeFive: '[手掌]',
  Knife: '[剪刀]',
  Hi: '[招手]',
  No: '[不要]',
  Hold: '[举着]',
  Think: '[思考]',
  Pig: '[猪头]',
  NoListen: '[不听]',
  NoLook: '[不看]',
  NoWords: '[不说]',
  Monkey: '[猴子]',
  Bomb: '[炸弹]',
  Cloud: '[筋斗云]',
  Rocket: '[火箭]',
  Ambulance: '[救护车]',
  Poop: '[便便]',
}

export default LocaleConfig
