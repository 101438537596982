import React, { useEffect, useState } from 'react';
import './App.css';
import { IM } from './home';
import { Provider } from './components';

export interface MessageType {
  type: 'YXIM'
  account: string
  token: string
  appKey: string
}
function App() {
  const [IM_Info, setIM_Info] = useState<Omit<MessageType, 'type'>>()
  useEffect(() => {
    // 监听 message 事件
    window.addEventListener("message", receiveMessageFromIndex, false);
  }, [])

  const receiveMessageFromIndex = (event: MessageEvent<MessageType>) => {
    if (event.data?.type == 'YXIM') {
      setIM_Info(event.data)
    }
  }

  return <div style={{ width: '100%', height: '100%', borderRadius: '8px' }}>
    {
      IM_Info && <Provider initOptions={{
        appkey: IM_Info.appKey,
        account: IM_Info.account,
        token: IM_Info.token,
      }}
        sdkVersion={1}>
        <IM />
      </Provider>
    }
  </div>
}

export default App;
